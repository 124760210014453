<div *ngIf="!registration.subscriptionComplete" class="alert alert-danger my-3 text-center" role="alert">
    Please Complete your registration
</div>
<p>
    <app-ceased-trading [supplierCodes]="selectedSuppliers"></app-ceased-trading>
    <ng-container *ngIf="this.comparisonSituation === ComparisonSituations.WithStandard">
        {{ 'introduction-to-standard-tariff-comparison' | cmsText | async }}
        <br />
    </ng-container>
    <ng-container *ngIf="this.comparisonSituation === ComparisonSituations.WithCurrent">
        {{ 'introduction-to-current-tariff-comparison' | cmsText | async }}
    </ng-container>
    <ng-container *ngIf="this.comparisonSituation === ComparisonSituations.DefaultAndRenewal">
        {{ 'introduction-to-standard-or-renewal-comparison' | cmsText | async }}
    </ng-container>
    <ng-container *ngIf="this.comparisonSituation === ComparisonSituations.None">
        {{ 'introduction-to-no-comparison' | cmsText | async }}
    </ng-container>
</p>

<p [ngClass]="{ 'alert alert-primary': !registration.product.knowsEnergyConsumption }">
    <ng-container *ngIf="!registration.product.knowsEnergyConsumption">
        {{ 'introduction-to-average-consumption-used' | cmsText | async }}
        <br />
    </ng-container>
    <a
        href="#"
        (click)="$event.preventDefault(); onNavigateToTab('product')"
        proposalInteractionEvent
        [eventArgs]="{ interactionType: 'edit data' }"
    >
        Click here if you want to change your details.
    </a>
</p>

<div class="card-group mb-3">
    <div
        class="card text-center order-2 order-sm-1"
        *ngIf="this.comparisonSituation === ComparisonSituations.WithStandard"
    >
        <div class="card-header">
            <h6 class="text-uppercase mb-0">{{ comparisonTariffName }}</h6>
        </div>
        <div class="card-body">
            <ng-container *ngIf="comparisonTariffTotalCost">
                <p class="card-text">{{ 'standard-tariff-text' | cmsText | async }}</p>
                <div class="card-text">
                    <p class="text-center h2">
                        {{ comparisonTariffTotalCost | currency : 'GBP' : 'symbol' : '1.0-0' }}
                    </p>
                    <p class="text-center">
                        {{ comparisonTariffTotalCost / 12 | currency : 'GBP' : 'symbol' : '1.0-0'
                        }}{{ 'per-month' | cmsText | async }}
                    </p>
                </div>
            </ng-container>
            <ng-container *ngIf="!comparisonTariffTotalCost">
                <p class="card-text">{{ 'decision-zero-comparison-cost' | cmsText | async }}</p>
            </ng-container>
        </div>
    </div>

    <div
        class="card text-center order-2 order-sm-1"
        *ngIf="
            comparisonSituation === ComparisonSituations.DefaultAndRenewal &&
            (comparisonAbTest === WhatToTest.Nothing || comparisonAbTest === WhatToTest.NoComparison)
        "
    >
        <div class="card-header">
            <h6 class="text-uppercase mb-0">{{ comparisonTariffName }}</h6>
        </div>
        <div class="card-body">
            <ng-container *ngIf="comparisonTariffTotalCost && registration.decision.selectedComparison">
                <p class="card-text" *ngIf="!registration.decision.wantsToCompareToRenewalTariff">
                    {{ 'standard-tariff-text' | cmsText | async }}
                </p>
                <p class="card-text" *ngIf="registration.decision.wantsToCompareToRenewalTariff">
                    Your renewal tariff
                </p>
                <div class="card-text">
                    <p class="text-center h2">
                        {{ comparisonTariffTotalCost | currency : 'GBP' : 'symbol' : '1.0-0' }}
                    </p>
                    <p class="text-center">
                        {{ comparisonTariffTotalCost / 12 | currency : 'GBP' : 'symbol' : '1.0-0'
                        }}{{ 'per-month' | cmsText | async }}
                    </p>
                </div>
            </ng-container>
            <ng-container *ngIf="!comparisonTariffTotalCost && registration.decision.selectedComparison">
                <p class="card-text">{{ 'decision-zero-comparison-cost' | cmsText | async }}</p>
            </ng-container>
            <div class="card-text">
                <button
                    id="compareToRenewalTariffButton"
                    class="btn btn-sm btn-primary"
                    (click)="compareToRenewalTariff()"
                    proposalInteractionEvent
                    [eventArgs]="{ interactionType: 'popup' }"
                >
                    {{ !registration.decision.wantsToCompareToRenewalTariff ? 'Compare with' : 'Change' }}
                    your renewal contract
                </button>
                <button
                    id="compareToStandardTariffButton"
                    class="btn btn-sm btn-primary mt-2"
                    (click)="compareToStandardTariff()"
                    proposalInteractionEvent
                    *ngIf="
                        !registration.decision.selectedComparison ||
                        registration.decision.wantsToCompareToRenewalTariff
                    "
                >
                    {{ 'compare-with-standard-tariff' | cmsText | async }}
                </button>
            </div>
        </div>
    </div>

    <div
        class="card text-center order-2 order-sm-1"
        *ngIf="
            comparisonSituation === ComparisonSituations.DefaultAndRenewal &&
            comparisonAbTest === WhatToTest.DefaultComparison
        "
    >
        <div class="card-header">
            <h6 class="text-uppercase mb-0">{{ comparisonTariffName }}</h6>
        </div>
        <div class="card-body">
            <ng-container *ngIf="comparisonTariffTotalCost">
                <p class="card-text" *ngIf="!registration.decision.wantsToCompareToRenewalTariff">
                    {{ 'standard-tariff-text' | cmsText | async }}
                </p>
                <p class="card-text" *ngIf="registration.decision.wantsToCompareToRenewalTariff">
                    Your renewal tariff
                </p>
                <div class="card-text">
                    <p class="text-center h2">
                        {{ comparisonTariffTotalCost | currency : 'GBP' : 'symbol' : '1.0-0' }}
                    </p>
                    <p class="text-center">
                        {{ comparisonTariffTotalCost / 12 | currency : 'GBP' : 'symbol' : '1.0-0'
                        }}{{ 'per-month' | cmsText | async }}
                    </p>
                </div>
            </ng-container>
            <ng-container *ngIf="!comparisonTariffTotalCost">
                <p class="card-text">{{ 'decision-zero-comparison-cost' | cmsText | async }}</p>
            </ng-container>
            <div class="card-text">
                <button
                    id="compareToRenewalTariffButton"
                    class="btn btn-sm btn-primary"
                    (click)="compareToRenewalTariff()"
                    proposalInteractionEvent
                    [eventArgs]="{ interactionType: 'popup' }"
                >
                    {{ !registration.decision.wantsToCompareToRenewalTariff ? 'Compare with' : 'Change' }}
                    your renewal contract
                </button>
                <button
                    *ngIf="registration.decision.wantsToCompareToRenewalTariff"
                    id="compareToStandardTariffButton"
                    class="btn btn-sm btn-secondary mt-2"
                    (click)="compareToStandardTariff()"
                    proposalInteractionEvent
                    [eventArgs]="{ interactionType: 'popup' }"
                >
                    {{ 'compare-with-standard-tariff' | cmsText | async }}
                </button>
            </div>
        </div>
    </div>

    <div
        class="card text-center order-2 order-sm-1"
        *ngIf="comparisonSituation === ComparisonSituations.WithCurrent"
    >
        <div class="card-header">
            <h6 class="text-uppercase mb-0">{{ comparisonTariffName }}</h6>
        </div>
        <div class="card-body">
            <ng-container>
                <ng-container *ngIf="comparisonTariffTotalCost">
                    <p class="card-text" *ngIf="!registration.decision.wantsToCompareToOwnTariff">
                        {{ 'standard-tariff-text' | cmsText | async }}
                    </p>
                    <p class="card-text" *ngIf="registration.decision.wantsToCompareToOwnTariff">
                        {{ 'current-tariff-text' | cmsText | async }}
                    </p>
                    <div class="card-text">
                        <p class="text-center h2">
                            {{ comparisonTariffTotalCost | currency : 'GBP' : 'symbol' : '1.0-0' }}
                        </p>
                        <p class="text-center">
                            {{ comparisonTariffTotalCost / 12 | currency : 'GBP' : 'symbol' : '1.0-0'
                            }}{{ 'per-month' | cmsText | async }}
                        </p>
                    </div>
                </ng-container>
                <ng-container *ngIf="!comparisonTariffTotalCost">
                    <p class="card-text">{{ 'decision-zero-comparison-cost' | cmsText | async }}</p>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div class="card text-center order-1 order-sm-2">
        <div
            class="card-header"
            [ngClass]="{
                'bg-success text-white':
                    comparisonTariffSaving > 0 || comparisonSituation === ComparisonSituations.None
            }"
        >
            <h6 class="text-uppercase mb-0">Your offer</h6>
        </div>
        <div class="card-body">
            <div class="card-text">
                <p>{{ 'proposal-card-text-your-offer' | cmsText | async }}</p>
                <p
                    class="text-center h2"
                    [ngClass]="{
                        'text-success':
                            comparisonTariffSaving > 0 || comparisonSituation === ComparisonSituations.None
                    }"
                >
                    {{ registration.proposal.winningTariffTotalCost | currency : 'GBP' : 'symbol' : '1.0-0' }}
                </p>
                <p class="text-center" *ngIf="comparisonSituation !== ComparisonSituations.None">
                    {{
                        registration.proposal.winningTariffTotalCost / 12
                            | currency : 'GBP' : 'symbol' : '1.0-0'
                    }}{{ 'per-month' | cmsText | async }}
                </p>
                <p *ngIf="comparisonSituation === ComparisonSituations.None">
                    gas:
                    <b>{{
                        registration.proposal.winningTariffGasCost / 12
                            | currency : 'GBP' : 'symbol' : '1.0-0'
                    }}</b
                    >{{ 'per-month' | cmsText | async }} <br />electricity:
                    <b>{{
                        registration.proposal.winningTariffElectricityCost / 12
                            | currency : 'GBP' : 'symbol' : '1.0-0'
                    }}</b
                    >{{ 'per-month' | cmsText | async }}
                </p>
            </div>
            <div class="card-text">
                <button
                    class="btn btn-sm btn-primary px-5"
                    (click)="modalService.open(calculationDetailsPopup, { size: 'lg' })"
                    proposalInteractionEvent
                    [eventArgs]="{ interactionType: 'popup' }"
                >
                    View calculation
                </button>
            </div>
        </div>
    </div>
</div>

<div
    id="existingCustomerMessage"
    class="card mb-3 border-1"
    *ngIf="hasExistingCustomerCheck && isExistingCustomer"
>
    <div class="px-3 pt-3 pb-0">
        <span
            class="fw-bold"
            [innerHtml]="
                'winning-suppler-existing-customer-message-' + supplierNameKebabCase | cmsText | async
            "
        >
        </span>
    </div>
</div>

<div
    [ngClass]="{
        'card mb-3': true,
        'border-success big-border': comparisonTarriffIsProfit,
        'border-1': !comparisonTarriffIsProfit,
        'saving-very-small': !comparisonTarriffIsProfit
    }"
>
    <div class="p-3">
        <div
            class="text-center"
            *ngIf="
                comparisonTariffTotalCost &&
                (comparisonSituation !== ComparisonSituations.DefaultAndRenewal ||
                    (comparisonSituation === ComparisonSituations.DefaultAndRenewal &&
                        registration.decision.selectedComparison))
            "
        >
            <span class="fw-bold">
                <span *ngIf="comparisonTarriffIsProfit">
                    {{ 'estimated-annual-savings' | cmsText | async }}
                    <span class="text-success">
                        {{ comparisonTariffSaving | currency : 'GBP' : 'symbol' : '1.0-0' }}</span
                    >
                </span>
                <span *ngIf="!comparisonTarriffIsProfit">
                    {{ 'estimated-annual-loss' | cmsText | async }}
                    <span>
                        {{ comparisonTariffSaving | absoluteNumber | currency : 'GBP' : 'symbol' : '1.0-0' }}
                        more.</span
                    >
                </span>
            </span>
        </div>
    </div>
</div>

<form [formGroup]="form" (submit)="submit()" novalidate appfocusFirstInvalidField>
    <p class="fw-bold h4 text-center">Would you like to switch?</p>
    <div class="row mt-3">
        <div class="col-sm order-sm-1 mb-3">
            <button
                type="button"
                class="btn btn-secondary float-sm-left btn-block declineOfferButton"
                (click)="openDeclinePopup(declineModal)"
                proposalInteractionEvent
                [eventArgs]="{ interactionType: 'popup' }"
            >
                No, not this time
            </button>
        </div>
        <div class="col-sm order-sm-12 mb-3">
            <button
                id="nextButton"
                type="button"
                class="btn btn-primary px-5 btn-block acceptOfferButton"
                (click)="acceptOffer(targetDecision)"
            >
                Yes, next step &nbsp;
                <i class="fa fa-chevron-right align-middle"></i>
            </button>
        </div>
    </div>

    <div class="mt-3">
        <p class="h5">More information</p>
        <app-proposal-information [registration]="registration" [paymentMethod]="paymentMethod">
        </app-proposal-information>
    </div>
    <div class="targetDecision" #targetDecision></div>
    <div *ngIf="showDecision">
        <div class="card mt-3">
            <div class="card-body">
                <div class="card-title clearfix">
                    <h5>
                        {{ 'decision-title' | cmsText | async }}
                        <img
                            [attr.src]="this.supplierLogoUri$ | async"
                            [attr.alt]="this.registration.proposal.winningTariffSupplierName"
                            class="float-lg-end"
                            width="100"
                        />
                    </h5>
                </div>

                <div class="pt-2 border-top">
                    <div class="alert alert-primary">
                        <span>{{ 'decision-payment-method-label' | cmsText | async }}&nbsp;</span>
                        <span class="fw-bold" *ngIf="paymentMethod === PaymentMethod.PrePayment">{{
                            'decision-payment-method-prepayment' | cmsText | async
                        }}</span>
                        <span class="fw-bold" *ngIf="paymentMethod === PaymentMethod.MonthlyDirectDebit">{{
                            'decision-payment-method-monthlydirectdebit' | cmsText | async
                        }}</span>
                    </div>

                    <div
                        *ngIf="
                            registration.proposal.winningTariffSupplierName === 'Shell Energy' &&
                            paymentMethod === PaymentMethod.PrePayment
                        "
                        [innerHtml]="'decision-shell-energy-ppm-more-info' | cmsText | async"
                    ></div>
                    <div
                        *ngIf="
                            registration.proposal.winningTariffSupplierName === 'Shell Energy' &&
                            paymentMethod === PaymentMethod.MonthlyDirectDebit
                        "
                        [innerHtml]="'decision-shell-energy-mdd-more-info' | cmsText | async"
                    ></div>

                    <div formGroupName="generalFormGroup" *ngIf="optionalSalutations">
                        <div class="col-sm-3 row">
                            <label for="salutation" class="form-label">
                                <app-content key="salutation-decision-title"></app-content>
                            </label>
                            <div class="input-group">
                                <select
                                    class="form-select"
                                    id="salutation"
                                    name="salutation"
                                    formControlName="salutation"
                                >
                                    <option [ngValue]="null" disabled>
                                        <app-content key="option-choose-one"></app-content>
                                    </option>
                                    <option *ngFor="let item of optionalSalutations" [ngValue]="item">
                                        {{ item }}
                                    </option>
                                </select>
                                <div
                                    *ngIf="salutation.invalid && (salutation.dirty || submitRequested)"
                                    class="text-danger"
                                >
                                    <span *ngIf="salutation.errors.required">
                                        <app-content key="salutation-error-required"></app-content>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div formGroupName="contactFormGroup" *ngIf="missingMpan">
                        <label for="mpan" class="form-label">
                            {{ 'contact-mpan' | cmsText | async }}
                        </label>
                        <app-tooltip-enuk
                            cmsKey="proposal-mpan-tooltip"
                            targetCmsKey="contact-mpan"
                        ></app-tooltip-enuk>
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control"
                                formControlName="mpan"
                                *ngIf="mpansLookup.length === 0; else mpansDropdown"
                            />
                            <ng-template #mpansDropdown>
                                <select
                                    class="form-select"
                                    name="mpansDropdown"
                                    id="mpansDropdown"
                                    formControlName="mpan"
                                >
                                    <option [ngValue]="null">
                                        {{ 'option-choose-one' | cmsText | async }}
                                    </option>
                                    <option *ngFor="let mpan of mpansLookup" [ngValue]="mpan">
                                        {{ mpan }}
                                    </option>
                                </select>
                            </ng-template>
                        </div>
                        <div *ngIf="mpan.invalid && (mpan.dirty || submitRequested)" class="text-danger">
                            <span *ngIf="mpan.errors.required">
                                {{ 'contact-mpan-required' | cmsText | async }}
                            </span>
                            <div *ngIf="mpan.errors.mpan">
                                {{ 'contact-mpan-invalid' | cmsText | async }}
                            </div>
                        </div>
                    </div>

                    <div formGroupName="contactFormGroup" *ngIf="missingMprn">
                        <label for="mprn" class="form-label">
                            {{ 'contact-mprn' | cmsText | async }}
                        </label>
                        <app-tooltip-enuk
                            cmsKey="proposal-mprn-tooltip"
                            targetCmsKey="contact-mprn"
                        ></app-tooltip-enuk>
                        <div class="input-group">
                            <input id="mprn" type="text" class="form-control" formControlName="mprn" />
                        </div>
                        <div *ngIf="mprn.invalid && (mprn.dirty || submitRequested)" class="text-danger">
                            <span *ngIf="mprn.errors.required">
                                {{ 'contact-mprn-required' | cmsText | async }}
                            </span>
                            <div *ngIf="mprn.errors.mprn">
                                {{ 'contact-mprn-invalid' | cmsText | async }}
                            </div>
                        </div>
                    </div>

                    <div formGroupName="generalFormGroup">
                        <label for="birthDate" class="form-label">
                            {{ 'decision-payment-birth-date' | cmsText | async }}
                        </label>
                        <div class="input-group">
                            <input
                                class="form-control"
                                placeholder="dd/mm/yyyy"
                                name="dp"
                                ngbDatepicker
                                #dp="ngbDatepicker"
                                [startDate]="startDate"
                                id="birthDate"
                                formControlName="birthDate"
                                [minDate]="addYearsFromToday(-120, true)"
                                [maxDate]="addYearsFromToday(-18)"
                            />
                            <button class="btn btn-secondary" (click)="dp.toggle()" type="button">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                        <div
                            *ngIf="birthDate.invalid && (birthDate.dirty || submitRequested)"
                            class="text-danger"
                        >
                            <span *ngIf="birthDate.errors.required">
                                {{ 'decision-payment-birth-date-required' | cmsText | async }}
                            </span>
                            <div *ngIf="birthDate.errors.ngbDate?.invalid">
                                {{ 'decision-payment-birth-date-invalid' | cmsText | async }}
                            </div>
                            <div *ngIf="birthDate.errors.tooOld">
                                {{ 'decision-payment-birth-date-too-old' | cmsText | async }}
                            </div>
                            <div *ngIf="birthDate.errors.tooYoung">
                                {{ 'decision-payment-birth-date-too-young' | cmsText | async }}
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isExistingCustomerBasedOnActualSuppliers" formGroupName="generalFormGroup">
                        <label for="supplierAccountNumber " class="form-label">{{
                            'supplier-account-number-label' | cmsText | async
                        }}</label>
                        <input
                            class="form-control"
                            id="supplierAccountNumber"
                            formControlName="supplierAccountNumber"
                        />
                        <div
                            *ngIf="
                                supplierAccountNumber.invalid &&
                                (supplierAccountNumber.dirty || submitRequested)
                            "
                            class="text-danger"
                        >
                            <span *ngIf="supplierAccountNumber.errors.maxlength">
                                {{ 'supplier-account-number-error-maxlength' | cmsText | async }}
                            </span>
                        </div>
                    </div>

                    <div formGroupName="generalFormGroup">
                        <div class="radio">
                            <label for="hasSmartMeter" class="form-label">
                                {{ 'decision-payment-has-smart-meter-label' | cmsText | async }}
                            </label>
                            <app-tooltip-enuk
                                *ngIf="this.registration.proposal.winningTariffSupplierName === 'So Energy'"
                                cmsKey="proposal-smart-meter-so-energy-tooltip"
                                targetCmsKey="decision-payment-has-smart-meter-label"
                            ></app-tooltip-enuk>
                            <app-tooltip-enuk
                                *ngIf="this.registration.proposal.winningTariffSupplierName !== 'So Energy'"
                                cmsKey="proposal-smart-meter-tooltip"
                                targetCmsKey="decision-payment-has-smart-meter-label"
                            ></app-tooltip-enuk>
                            <div class="form-check custom-radio">
                                <input
                                    type="radio"
                                    class="form-check-input"
                                    id="hasSmartMeterYes"
                                    formControlName="hasSmartMeter"
                                    [value]="true"
                                />
                                <label class="form-check-label" for="hasSmartMeterYes">
                                    {{ 'yes' | cmsText | async }}
                                </label>
                            </div>
                            <div class="form-check custom-radio">
                                <input
                                    type="radio"
                                    class="form-check-input"
                                    id="hasSmartMeterNo"
                                    formControlName="hasSmartMeter"
                                    [value]="false"
                                />
                                <label class="form-check-label" for="hasSmartMeterNo">
                                    {{ 'no' | cmsText | async }}
                                </label>
                            </div>
                        </div>
                        <ng-container
                            *ngIf="
                                hasSmartMeter.value === true &&
                                this.registration.proposal.winningTariffSupplierName != 'So Energy'
                            "
                        >
                            <div class="text-danger">
                                <span>
                                    {{
                                        (!hasCustomSmartMeterWarning
                                            ? 'decision-payment-has-smart-meter-warning'
                                            : 'decision-payment-has-smart-meter-warning-' +
                                              supplierNameKebabCase
                                        )
                                            | cmsText
                                            | async
                                    }}
                                </span>

                                <!-- Hacky (remove after may-2021) -->
                                <div class="text-center mt-2" *ngIf="excluded">
                                    <button
                                        type="button"
                                        (click)="wantToParticipateInNextAuction()"
                                        class="btn btn-primary px-5 d-print-none"
                                    >
                                        {{ 'participate-in-next-auction-button' | cmsText | async }}
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                        <div
                            *ngIf="hasSmartMeter.invalid && (hasSmartMeter.dirty || submitRequested)"
                            class="text-danger"
                        >
                            <span *ngIf="hasSmartMeter.errors.required">
                                {{ 'decision-payment-has-smart-meter-error-required' | cmsText | async }}
                            </span>
                        </div>
                    </div>

                    <ng-container
                        *ngIf="
                            this.registration.proposal.winningTariffSupplierName !== 'SSE';
                            else sseWaiveDateInfo
                        "
                    >
                        <div formGroupName="generalFormGroup" *ngIf="showWaiveDateCheckbox">
                            <div class="form-check custom-checkbox">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="waitTillWaiveDateToSwitch"
                                    formControlName="waitTillWaiveDateToSwitch"
                                    [value]="waitTillWaiveDateToSwitch.value"
                                />
                                <label class="form-check-label" for="waitTillWaiveDateToSwitch">
                                    <app-content
                                        key="decision-waivedate-checkbox-label"
                                        [params]="[{ waiveDate: prettyWaiveDate }]"
                                    >
                                    </app-content>
                                </label>
                            </div>
                        </div>

                        <div *ngIf="showWaiveDateInfoText">
                            {{ 'decision-waivedate-info' | cmsText | async }}
                        </div>
                    </ng-container>
                    <ng-template #sseWaiveDateInfo>
                        <div *ngIf="showWaiveDateCheckbox || showWaiveDateInfoText">
                            <p [innerHtml]="'decision-waivedate-sse-info' | cmsText | async"></p>
                        </div>
                    </ng-template>

                    <div
                        formGroupName="generalFormGroup"
                        *ngIf="this.registration.proposal.winningTariffSupplierName === 'E.ON'"
                    >
                        <label for="employmentStatus" class="form-label">
                            {{ 'decision-employment-status-label' | cmsText | async }}
                        </label>
                        <select
                            class="form-select"
                            name="employmentStatus"
                            id="employmentStatus"
                            formControlName="employmentStatus"
                        >
                            <option [ngValue]="null">
                                {{ 'option-choose-one' | cmsText | async }}
                            </option>
                            <option *ngFor="let item of employmentStatuses" [ngValue]="item.key">
                                {{ item.value }}
                            </option>
                        </select>
                        <div
                            *ngIf="employmentStatus.invalid && (employmentStatus.dirty || submitRequested)"
                            class="text-danger"
                        >
                            <span *ngIf="employmentStatus.errors.required">
                                {{ 'decision-employment-status-error-required' | cmsText | async }}
                            </span>
                        </div>
                    </div>

                    <div
                        formGroupName="generalFormGroup"
                        *ngIf="this.registration.proposal.winningTariffSupplierName === 'E.ON'"
                    >
                        <label for="residentialStatus" class="form-label">
                            {{ 'decision-residential-status-label' | cmsText | async }}
                        </label>
                        <select
                            class="form-select"
                            name="residentialStatus"
                            id="residentialStatus"
                            formControlName="residentialStatus"
                        >
                            <option [ngValue]="null">
                                {{ 'option-choose-one' | cmsText | async }}
                            </option>
                            <option *ngFor="let item of residentialStatuses" [ngValue]="item.key">
                                {{ item.value }}
                            </option>
                        </select>
                        <div
                            *ngIf="residentialStatus.invalid && (residentialStatus.dirty || submitRequested)"
                            class="text-danger"
                        >
                            <span *ngIf="residentialStatus.errors.required">
                                {{ 'decision-residential-status-error-required' | cmsText | async }}
                            </span>
                        </div>
                    </div>

                    <div
                        formGroupName="generalFormGroup"
                        *ngIf="this.showCurrentAddressYears && !isExistingCustomerBasedOnActualSuppliers"
                    >
                        <div class="row">
                            <div class="col">
                                <label for="currentAddressYears" class="form-label">{{
                                    'decision-current-address-how-long-label' | cmsText | async
                                }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-6">
                                {{ 'decision-address-years' | cmsText | async }}
                                <select
                                    class="form-select"
                                    name="currentAddressYears"
                                    id="currentAddressYears"
                                    formControlName="currentAddressYears"
                                >
                                    <option [ngValue]="null" selected disabled>
                                        {{ 'option-choose-one' | cmsText | async }}
                                    </option>
                                    <option *ngFor="let item of addressYears" [ngValue]="item.key">
                                        {{ item.value }}
                                    </option>
                                </select>
                            </div>
                            <div
                                class="col col-6"
                                *ngIf="currentAddressYears.value !== null && currentAddressYears.value < 5"
                            >
                                {{ 'decision-address-months' | cmsText | async }}
                                <select
                                    class="form-select"
                                    name="currentAddressMonths"
                                    id="currentAddressMonths"
                                    formControlName="currentAddressMonths"
                                >
                                    <option [ngValue]="null">
                                        {{ 'option-choose-one' | cmsText | async }}
                                    </option>
                                    <option *ngFor="let item of addressMonths" [ngValue]="item">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                    *ngIf="
                                        currentAddressYears.invalid &&
                                        (currentAddressYears.dirty || submitRequested)
                                    "
                                    class="text-danger"
                                >
                                    <span *ngIf="currentAddressYears.errors.required">
                                        {{
                                            'decision-current-address-years-error-required' | cmsText | async
                                        }}
                                    </span>
                                </div>
                                <div
                                    *ngIf="
                                        currentAddressMonths.invalid &&
                                        (currentAddressMonths.dirty || submitRequested)
                                    "
                                    class="text-danger"
                                >
                                    <span *ngIf="currentAddressMonths.errors.required">
                                        {{
                                            'decision-current-address-months-error-required' | cmsText | async
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="!isExistingCustomerBasedOnActualSuppliers">
                        <div *ngFor="let previousAddress of previousAddresses.controls">
                            <app-decision-previous-address
                                [formControl]="abstractControlToFormControl(previousAddress)"
                                [submitRequested]="submitRequested"
                            >
                            </app-decision-previous-address>
                        </div>
                    </ng-container>

                    <div formGroupName="generalFormGroup">
                        <div class="form-check custom-checkbox">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="hasSeparateBillingAddress"
                                formControlName="hasSeparateBillingAddress"
                                value="false"
                            />
                            <label class="form-check-label" for="hasSeparateBillingAddress">
                                {{ 'decision-payment-switch-address-label' | cmsText | async }}
                            </label>
                        </div>
                        <div
                            *ngIf="
                                hasSeparateBillingAddress.invalid &&
                                (hasSeparateBillingAddress.dirty || submitRequested)
                            "
                            class="text-danger"
                        >
                            <span *ngIf="hasSeparateBillingAddress.errors.required">
                                {{ 'decision-payment-switch-address-error-required' | cmsText | async }}
                            </span>
                        </div>
                    </div>

                    <div *ngIf="hasSeparateBillingAddress.value" formGroupName="generalFormGroup">
                        <div>
                            <label for="billingPostcode form-label">
                                {{ 'postcode-label' | cmsText | async }}
                            </label>
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    type="text"
                                    (keydown.enter)="$event.preventDefault()"
                                    formControlName="billingPostcode"
                                />
                            </div>
                            <div
                                *ngIf="billingPostcode.invalid && (billingPostcode.dirty || submitRequested)"
                                class="text-danger"
                            >
                                <span *ngIf="billingPostcode.errors.required">
                                    {{ 'postcode-error-required' | cmsText | async }}
                                </span>
                                <span *ngIf="billingPostcode.errors.pattern">
                                    {{ 'postcode-error-pattern' | cmsText | async }}
                                </span>
                            </div>
                            <span class="text-danger" *ngIf="billingPostcodeError">
                                {{ 'postcode-error-server' | cmsText | async }}
                            </span>
                        </div>

                        <div *ngIf="billingPostcode.valid">
                            <label for="billingAddress form-labe">
                                <i class="fa fa-spinner fa-spin fa-2x" *ngIf="!billingAddress"></i>
                                <span *ngIf="billingAddress">{{ 'address-label' | cmsText | async }}</span>
                            </label>
                            <div *ngIf="billingAddress" class="input-group">
                                <select
                                    class="form-select"
                                    name="billingAddress"
                                    id="billingAddress"
                                    formControlName="billingAddress"
                                >
                                    <option [ngValue]="null">
                                        {{ 'address-placeholder' | cmsText | async }}
                                    </option>
                                    <option *ngFor="let addr of addresses" [ngValue]="addr">
                                        {{ addr.organisation }} {{ addr.buildingName }}
                                        {{ addr.subBuilding }}
                                        {{ addr.buildingNumber }} {{ addr.dependentThoroughfare }}
                                        {{ addr.thoroughfare }} {{ addr.doubleDependentLocality }}
                                        {{ addr.dependentLocality }}
                                        {{ addr.town }} {{ addr.county }} {{ addr.postcode }}
                                    </option>
                                </select>
                            </div>
                            <div class="text-danger">
                                <span
                                    *ngIf="
                                        billingAddress.invalid && (billingAddress.dirty || submitRequested)
                                    "
                                >
                                    <span *ngIf="billingAddress.errors.required">
                                        {{ 'address-error-required' | cmsText | async }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="paymentMethod === PaymentMethod.PrePayment">
                        <!-- Empty right now -->
                    </div>

                    <div *ngIf="showBankQuestions">
                        <h6>{{ 'bank-details-label' | cmsText | async }}</h6>
                        <div class="alert alert-warning" *ngIf="showBankDetailsWarningToEonCustomers">
                            {{ 'direct-debit-account-holder-warning' | cmsText | async }}
                        </div>
                        <div formGroupName="bankFormGroup">
                            <label for="firstName" class="form-label">{{
                                'first-name-label' | cmsText | async
                            }}</label>
                            <input class="form-control" id="firstName" formControlName="firstName" />
                            <div
                                *ngIf="firstName.invalid && (firstName.dirty || submitRequested)"
                                class="text-danger"
                            >
                                <span *ngIf="firstName.errors.required">
                                    {{ 'first-name-error-required' | cmsText | async }}
                                </span>
                                <span *ngIf="firstName.errors.maxlength">
                                    {{ 'first-name-error-maxlength' | cmsText | async }}
                                </span>
                            </div>
                        </div>

                        <div formGroupName="bankFormGroup">
                            <label for="lastName" class="form-label">{{
                                'surname-label' | cmsText | async
                            }}</label>
                            <input class="form-control" id="lastName" formControlName="lastName" />
                            <div
                                *ngIf="lastName.invalid && (lastName.dirty || submitRequested)"
                                class="text-danger"
                            >
                                <span *ngIf="lastName.errors.required">
                                    {{ 'surname-error-required' | cmsText | async }}
                                </span>
                                <span *ngIf="lastName.errors.maxlength">
                                    {{ 'surname-error-maxlength' | cmsText | async }}
                                </span>
                            </div>
                        </div>

                        <div formGroupName="bankAccountFormGroup">
                            <label for="accountNumber" class="form-label">{{
                                'account-number-label' | cmsText | async
                            }}</label>
                            <app-tooltip-enuk
                                cmsKey="acount-number-tooltip"
                                targetCmsKey="account-number-label"
                            ></app-tooltip-enuk>
                            <input
                                class="form-control"
                                id="accountNumber"
                                formControlName="accountNumber"
                                mask="00000000"
                            />
                            <div
                                *ngIf="accountNumber.invalid && (accountNumber.dirty || submitRequested)"
                                class="text-danger"
                            >
                                <span *ngIf="accountNumber.errors.required">
                                    {{ 'account-number-error-required' | cmsText | async }}
                                </span>
                                <span *ngIf="accountNumber.errors.minlength">
                                    {{ 'account-number-error-minlength' | cmsText | async }}
                                </span>
                            </div>
                        </div>

                        <div formGroupName="bankAccountFormGroup">
                            <label for="sortCode" class="form-label">{{
                                'sort-code-label' | cmsText | async
                            }}</label>
                            <app-tooltip-enuk
                                cmsKey="sort-code-tooltip"
                                targetCmsKey="sort-code-label"
                            ></app-tooltip-enuk>
                            <input
                                class="form-control"
                                id="sortCode"
                                formControlName="sortCode"
                                mask="00-00-00"
                            />
                            <div
                                *ngIf="sortCode.invalid && (sortCode.dirty || submitRequested)"
                                class="text-danger"
                            >
                                <span *ngIf="sortCode.errors.required">
                                    {{ 'sort-code-error-required' | cmsText | async }}
                                </span>
                                <span *ngIf="sortCode.errors.minlength">
                                    {{ 'sort-code-error-minlength' | cmsText | async }}
                                </span>
                            </div>
                        </div>

                        <div
                            *ngIf="bankAccountFormGroup?.errors?.bankAccountIsInvalid"
                            class="alert alert-danger"
                            role="alert"
                        >
                            {{ 'bank-account-or-sort-code-error-invalid' | cmsText | async }}
                        </div>

                        <div
                            formGroupName="bankFormGroup"
                            *ngIf="showPreferredPaymentDay; else preferredPaymentDayLabel"
                        >
                            <label for="preferredPaymentDay" class="form-label">{{
                                'payment-day-label' | cmsText | async
                            }}</label>
                            <ng-select
                                [items]="paymentDays"
                                placeholder="{{ 'option-choose-one' | cmsText | async }}"
                                id="preferredPaymentDay"
                                formControlName="preferredPaymentDay"
                                [selectOnTab]="true"
                            >
                            </ng-select>
                            <div
                                *ngIf="
                                    preferredPaymentDay.invalid &&
                                    (preferredPaymentDay.dirty || submitRequested)
                                "
                                class="text-danger"
                            >
                                <span *ngIf="preferredPaymentDay.errors.required">
                                    {{ 'payment-day-error-required' | cmsText | async }}
                                </span>
                            </div>
                        </div>
                        <ng-template #preferredPaymentDayLabel>
                            <app-content key="payment-day-{{ supplierNameKebabCase }}-label"></app-content>
                        </ng-template>

                        <div formGroupName="bankFormGroup">
                            <div class="form-check custom-checkbox">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="authorizedDirectDebit"
                                    formControlName="authorizedDirectDebit"
                                />
                                <label class="form-check-label" for="authorizedDirectDebit">
                                    {{ 'authorize-direct-debit-label' | cmsText | async }}
                                </label>
                                <ng-container>
                                    <app-tooltip-enuk
                                        [cmsKey]="
                                            'proposal-authorized-direct-debit-' +
                                            supplierNameKebabCase +
                                            '-tooltip'
                                        "
                                        targetCmsKey="authorize-direct-debit-label"
                                    ></app-tooltip-enuk>
                                </ng-container>
                            </div>
                            <div
                                class="alert alert-warning"
                                *ngIf="this.registration.proposal.winningTariffSupplierName === 'So Energy'"
                            >
                                {{ 'authorize-direct-debit-warning' | cmsText | async }}
                            </div>
                            <div
                                *ngIf="
                                    authorizedDirectDebit.invalid &&
                                    (authorizedDirectDebit.dirty || submitRequested)
                                "
                                class="text-danger"
                            >
                                <div
                                    id="authorizedDirectDebitError"
                                    *ngIf="authorizedDirectDebit.errors.required"
                                >
                                    {{ 'authorize-direct-debit-error-required' | cmsText | async }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="alert alert-warning"
                            *ngIf="this.registration.proposal.winningTariffSupplierName === 'Octopus Energy'"
                        >
                            {{ 'direct-debit-octopus-payment' | cmsText | async }}
                        </div>
                        <div
                            class="alert alert-warning"
                            *ngIf="this.registration.proposal.winningTariffSupplierName === 'Together Energy'"
                        >
                            {{ 'direct-debit-together-energy-payment' | cmsText | async }}
                        </div>
                    </div>

                    <div
                        formGroupName="generalFormGroup"
                        *ngIf="this.showRequirements && this.registration.product.priorityServiceRegister"
                    >
                        <label for="additionalRequirements" class="form-label">
                            {{ 'decision-additional-requirements-label' | cmsText | async }}
                        </label>
                        <select
                            class="form-select"
                            name="additionalRequirements"
                            id="additionalRequirements"
                            formControlName="additionalRequirements"
                            *ngIf="!this.allowSelectingMultipleRequirements"
                        >
                            <option [ngValue]="null">
                                {{ 'option-choose-one' | cmsText | async }}
                            </option>
                            <option *ngFor="let item of requirementsFlat" [ngValue]="item.key">
                                {{ item.value }}
                            </option>
                        </select>
                        <ng-container *ngIf="this.allowSelectingMultipleRequirements">
                            <div
                                *ngFor="
                                    let group of additionalRequirementsCategorized.controls;
                                    let i = index
                                "
                                class="card mt-1"
                            >
                                <div
                                    *ngIf="requirementsCategories[i][0].category !== null"
                                    class="card-header"
                                >
                                    {{ requirementsCategories[i][0].category }}
                                </div>
                                <div class="card-body">
                                    <div class="form-check custom-checkbox">
                                        <div
                                            *ngFor="
                                                let control of $any(
                                                    additionalRequirementsCategorized.controls[i]
                                                ).controls;
                                                let j = index
                                            "
                                        >
                                            <input
                                                type="checkbox"
                                                [formControl]="control"
                                                class="form-check-input"
                                                id="chkRequirements{{ i }}_{{ j }}"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="chkRequirements{{ i }}_{{ j }}"
                                            >
                                                {{ requirementsCategories[i][j].requirementLabel }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div>
                        <h6>{{ 'terms-conditions-title' | cmsText | async }}</h6>
                    </div>

                    <div
                        formGroupName="generalFormGroup"
                        *ngIf="
                            this.registration.proposal.winningTariffSupplierName === 'E.ON' &&
                            this.registration.product.priorityServiceRegister
                        "
                    >
                        <div class="form-check custom-checkbox">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="agreesToSupplierSharingInformationWithNetworkOperator"
                                formControlName="agreesToSupplierSharingInformationWithNetworkOperator"
                                [value]="true"
                            />
                            <label
                                class="form-check-label"
                                for="agreesToSupplierSharingInformationWithNetworkOperator"
                            >
                                {{
                                    'decision-agrees-to-supplier-sharing-information-with-network-operator-label'
                                        | cmsText
                                        | async
                                }}
                            </label>
                        </div>
                    </div>

                    <!-- allowsPublicity -->
                    <ng-container *ngIf="requirePublicityConsent">
                        <div formGroupName="generalFormGroup">
                            <div class="form-check custom-checkbox">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="allowsPublicity"
                                    formControlName="allowsPublicity"
                                />
                                <label class="form-check-label" for="allowsPublicity">
                                    {{
                                        'allows-publicity-' + this.supplierNameKebabCase + '-label'
                                            | cmsText
                                            | async
                                    }}
                                </label>
                            </div>
                        </div>

                        <div
                            formGroupName="generalFormGroup"
                            *ngIf="
                                allowsPublicity.value &&
                                (this.registration.proposal.winningTariffSupplierName === 'E.ON' ||
                                    this.registration.proposal.winningTariffSupplierName ===
                                        'Together Energy' ||
                                    this.registration.proposal.winningTariffSupplierName ===
                                        'Octopus Energy' ||
                                    this.registration.proposal.winningTariffSupplierName === 'Shell Energy')
                            "
                        >
                            <select
                                *ngIf="
                                    this.registration.proposal.winningTariffSupplierName !== 'E.ON' &&
                                    this.registration.proposal.winningTariffSupplierName !== 'Shell Energy'
                                "
                                class="form-select"
                                name="supplierContactMethod"
                                id="supplierContactMethod"
                                formControlName="supplierContactMethod"
                            >
                                <option [ngValue]="null">
                                    {{ 'option-choose-one' | cmsText | async }}
                                </option>
                                <option *ngFor="let item of contactMethods" [ngValue]="item.key">
                                    {{ item.value }}
                                </option>
                            </select>
                            <div
                                *ngIf="
                                    this.registration.proposal.winningTariffSupplierName === 'E.ON' ||
                                    this.registration.proposal.winningTariffSupplierName === 'Shell Energy'
                                "
                                class="form-check custom-checkbox"
                            >
                                <div
                                    *ngFor="let item of supplierContactMethods.controls; let i = index"
                                    class="form-check custom-checkbox"
                                >
                                    <input
                                        type="checkbox"
                                        [formControl]="abstractControlToFormControl(item)"
                                        id="chkContactMethod{{ i }}"
                                        class="form-check-input"
                                    />
                                    <label class="form-check-label" for="chkContactMethod{{ i }}">
                                        {{ contactMethods[i].value }}
                                    </label>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    supplierContactMethods.invalid &&
                                    (supplierContactMethods.dirty || submitRequested)
                                "
                                class="text-danger"
                            >
                                <span *ngIf="supplierContactMethods.errors.required">
                                    {{ 'decision-supplier-contact-method-error-required' | cmsText | async }}
                                </span>
                            </div>
                        </div>
                    </ng-container>

                    <!-- agreesToSupplierCommunicationThirdParty -->
                    <div formGroupName="generalFormGroup" *ngIf="requireThirdPartyCommunicationConsent">
                        <div class="form-check custom-checkbox">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="agreesToSupplierCommunicationThirdParty"
                                formControlName="agreesToSupplierCommunicationThirdParty"
                            />
                            <label class="form-check-label" for="agreesToSupplierCommunicationThirdParty">
                                {{
                                    'agrees-to-supplier-communication-third-party-' +
                                        supplierNameKebabCase +
                                        '-label'
                                        | cmsText
                                        | async
                                }}
                            </label>
                        </div>
                        <div
                            *ngIf="
                                agreesToSupplierCommunicationThirdParty.invalid &&
                                (agreesToSupplierCommunicationThirdParty.dirty || submitRequested)
                            "
                            class="text-danger"
                        ></div>
                    </div>

                    <div formGroupName="generalFormGroup">
                        <div class="form-check custom-checkbox">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="agreesToSupplierConditions"
                                formControlName="agreesToSupplierConditions"
                            />
                            <label class="form-check-label" for="agreesToSupplierConditions">
                                <app-content
                                    [key]="'supplier-terms-conditions-' + supplierNameKebabCase + '-label'"
                                    *ngIf="!registration.product.hasPrepaymentMeter"
                                >
                                </app-content>
                                <app-content
                                    [key]="
                                        'supplier-terms-conditions-' + supplierNameKebabCase + '-ppm-label'
                                    "
                                    *ngIf="registration.product.hasPrepaymentMeter"
                                >
                                </app-content>
                            </label>
                        </div>
                        <div
                            *ngIf="
                                agreesToSupplierConditions.invalid &&
                                (agreesToSupplierConditions.dirty || submitRequested)
                            "
                            class="text-danger"
                        >
                            <div *ngIf="agreesToSupplierConditions.errors.required">
                                {{ 'supplier-terms-conditions-error-required' | cmsText | async }}
                            </div>
                        </div>

                        <app-content
                            key="supplier-credit-check-eon"
                            *ngIf="this.registration.proposal.winningTariffSupplierName === 'E.ON'"
                        >
                        </app-content>
                    </div>

                    <div
                        formGroupName="generalFormGroup"
                        *ngIf="hasSeparateCreditCheckAgreement && !registration.product.hasPrepaymentMeter"
                    >
                        <div class="form-check custom-checkbox">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="agreesToSupplierCreditCheck"
                                formControlName="agreesToSupplierCreditCheck"
                            />
                            <label class="form-check-label" for="agreesToSupplierCreditCheck">
                                <app-content key="supplier-credit-check-{{ supplierNameKebabCase }}-label">
                                </app-content>
                            </label>
                        </div>
                        <div
                            *ngIf="
                                agreesToSupplierCreditCheck.invalid &&
                                (agreesToSupplierCreditCheck.dirty || submitRequested)
                            "
                            class="text-danger"
                        >
                            <div *ngIf="agreesToSupplierCreditCheck.errors.required">
                                {{ 'supplier-credit-check-error-required' | cmsText | async }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-sm-6 offset-sm-6 mb-0">
                <button
                    id="submitButton"
                    type="submit"
                    class="btn btn-primary px-5 btn-block acceptOfferSubmitButton"
                    [disabled]="(submitting && form.valid) || excluded"
                >
                    <i *ngIf="submitting && form.valid" class="fa fa-spinner fa-spin me-1"></i>
                    {{ 'accept-proposal-button' | cmsText | async }}
                    <i class="fa fa-chevron-right align-middle"></i>
                </button>
            </div>
        </div>
    </div>
</form>

<hr class="mb-3" />

<div class="row">
    <div class="col-sm-12 px-4">
        <h5 class="mb-4">{{ 'faq-proposal-title' | cmsText | async }}</h5>
        <app-mini-faq category="faq-category-mini-proposal"></app-mini-faq>
    </div>
</div>

<ng-template #declineModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'decline-offer-modal-title' | cmsText | async }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p [innerHTML]="'decline-offer-modal-text' | cmsText | async"></p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary me-auto" (click)="modal.close('Close click')">
            {{ 'previous-step-button' | cmsText | async }}
        </button>
        <button type="button" class="btn btn-primary" (click)="declineOffer(modal)">
            {{ 'confirm-proposal-button' | cmsText | async }}
        </button>
    </div>
</ng-template>

<ng-template #calculationDetailsPopup let-modal>
    <div class="modal-header">
        <p class="h5 modal-title">{{ 'tariff-details-title' | cmsText | async }}</p>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-tarrif-comparison [registration]="registration"></app-tarrif-comparison>
    </div>
    <div class="modal-footer">
        <div class="col text-start">
            <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">
                Back to proposal
            </button>
        </div>
    </div>
</ng-template>
