<label [for]="id" class="d-block form-label">
    {{ cmsLabel | cmsText | async }}
    <i *ngIf="isLoading && !readonly" class="fa fa-spinner fa-pulse ms-2"></i>
</label>
<div class="row">
    <div class="col-md-8">
        <ng-select
            [id]="id"
            [ngModel]="tariffName"
            (ngModelChange)="setTariff($event)"
            [readonly]="!enabled || readonly || isLoading"
            [items]="tariffs"
            [searchFn]="search"
            [selectOnTab]="true"
            bindValue="name"
            bindLabel="name"
            placeholder="{{ 'energy-contract-tariff-placeholder-label' | cmsText | async }}"
        >
            <ng-template ng-label-tmp let-item="item">
                <span [innerHTML]="item.name"></span>
                <span *ngIf="!item.paperBilling && item.paperlessBilling"> (Paperless billing)</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <div [innerHTML]="item.name"></div>
                <span *ngIf="!item.paperBilling && item.paperlessBilling"> (Paperless billing)</span>
            </ng-template>
        </ng-select>
    </div>
</div>
